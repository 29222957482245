import React from "react";
import { PageContainer } from "./PageContainer";

const SectionContainer = ({
  children,
  title,
  description,
  className,
  fullWidth,
}) => {
  return (
    <div
      className={`relative pt-12 pb-12 lg:pt-20 lg:pb-20 h-full ${
        fullWidth ? "" : "px-4 sm:px-6 lg:px-8"
      } ${className || ""}`}
    >
      <PageContainer>
        {!!title || !!description ? (
          <div className="text-center mb-16 max-w-xl lg:max-w-none mx-auto">
            {!!title ? (
              <h2 className="text-3xl tracking-tight font-bold text-gray-900 sm:text-4xl">
                {title}
              </h2>
            ) : null}
            {!!description ? (
              <p className="mt-3 max-w-2xl mx-auto text-lg text-gray-500 sm:mt-4">
                {description}
              </p>
            ) : null}
          </div>
        ) : null}
        {children}
      </PageContainer>
    </div>
  );
};

export { SectionContainer };
